var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('v-card',{staticClass:"mx-auto",attrs:{"loading":_vm.loading,"outlined":""}},[_c('template',{slot:"progress"},[_c('v-progress-linear',{attrs:{"color":_vm.$globalSettings.progressbarClasses,"height":"10","indeterminate":""}})],1),_c('v-card-title',[_c('v-row',[_c('v-col',{attrs:{"cols":"12","sm":"6"}},[_c('div',{staticClass:"secondary-text"},[_c('v-btn',{staticClass:"default",attrs:{"type":"button","disabled":_vm.loading,"small":"","icon":""},on:{"click":_vm.navigationBack}},[_c('v-icon',{attrs:{"small":""}},[_vm._v("fa-angle-left")])],1),_vm._v(" "+_vm._s(_vm.formActions.pageTitle)+" ")],1)]),_c('v-col',{staticClass:"text-right font-size-14 font-italic",attrs:{"cols":"12","sm":"6"}},[_vm._v(" "+_vm._s(_vm.$globalHelpers.getUserRoleName(_vm.formData.RoleId))+" ")])],1)],1),_c('v-divider'),_c('v-card-text',[_c('validation-observer',{ref:"observer",scopedSlots:_vm._u([{key:"default",fn:function(ref){
var invalid = ref.invalid;
return [_c('v-form',{on:{"submit":function($event){$event.preventDefault();return _vm.save.apply(null, arguments)}}},[_vm._v(" Office Information "),_c('v-row',[_c('v-col',{attrs:{"cols":"12","sm":"4"}},[_c('validation-provider',{attrs:{"name":"Name","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('v-text-field',{attrs:{"type":"text","error-messages":errors,"label":"Name *","disabled":_vm.loading,"required":""},model:{value:(_vm.formData.Name),callback:function ($$v) {_vm.$set(_vm.formData, "Name", $$v)},expression:"formData.Name"}})]}}],null,true)})],1),_c('v-col',{attrs:{"cols":"12","sm":"4"}},[_c('ValidationProvider',{attrs:{"name":"RoleId","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('v-select',{attrs:{"error-messages":errors,"items":_vm.roles,"item-value":"id","item-text":"label","label":"Role *","disabled":!_vm.formActions.roleEditEnabled,"required":""},on:{"change":_vm.prepareExporterTypes},model:{value:(_vm.formData.RoleId),callback:function ($$v) {_vm.$set(_vm.formData, "RoleId", $$v)},expression:"formData.RoleId"}})]}}],null,true)})],1),(_vm.formActions.showServiceOffice)?_c('v-col',{attrs:{"cols":"12","sm":"4"}},[_c('ValidationProvider',{attrs:{"name":"Service Office","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('v-select',{attrs:{"error-messages":errors,"items":_vm.serviceOffices,"item-value":"id","item-text":"label","label":"Service Office *","disabled":!_vm.formActions.serviceOfficeEditEnabled,"required":""},on:{"change":_vm.prepareExporterTypes},model:{value:(_vm.formData.ServiceOfficeId),callback:function ($$v) {_vm.$set(_vm.formData, "ServiceOfficeId", $$v)},expression:"formData.ServiceOfficeId"}})]}}],null,true)})],1):_vm._e(),(_vm.formActions.showDepartment)?_c('v-col',{attrs:{"cols":"12","sm":"4"}},[_c('ValidationProvider',{attrs:{"name":_vm.formData.RoleId === _vm.$globalEnums.roles.Exporter? 'Exporter Type': 'Department',"rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('v-select',{attrs:{"error-messages":errors,"items":_vm.exporterTypes,"item-value":"id","item-text":"label","label":_vm.formData.RoleId === _vm.$globalEnums.roles.Exporter? 'Exporter Type *': 'Department *',"disabled":!_vm.formActions.departmentEditEnabled,"required":""},model:{value:(_vm.formData.ExporterType),callback:function ($$v) {_vm.$set(_vm.formData, "ExporterType", $$v)},expression:"formData.ExporterType"}})]}}],null,true)})],1):_vm._e(),(_vm.formActions.showIsDepartmentHead)?_c('v-col',{attrs:{"cols":"12","sm":"4"}},[_c('ValidationProvider',{attrs:{"name":"Is Department Head","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('v-select',{attrs:{"error-messages":errors,"items":_vm.defaultSelectOptions,"item-value":"id","item-text":"label","label":"Is Department Head? *","disabled":!_vm.formActions.serviceOfficeEditEnabled,"required":""},model:{value:(_vm.formData.IsDepartmentHead),callback:function ($$v) {_vm.$set(_vm.formData, "IsDepartmentHead", $$v)},expression:"formData.IsDepartmentHead"}})]}}],null,true)})],1):_vm._e(),(_vm.formActions.isAdmin)?_c('v-col',{attrs:{"cols":"12","sm":"4"}},[_c('ValidationProvider',{attrs:{"name":"DesignationId","rules":{required: _vm.$route.params.type !== 'exporter'}},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('v-select',{attrs:{"error-messages":errors,"items":_vm.designations,"item-value":"id","item-text":"label","label":'Designation' + (_vm.$route.params.type !== 'exporter'? '*': ''),"disabled":!_vm.formActions.designationEditEnabled,"required":""},model:{value:(_vm.formData.DesignationId),callback:function ($$v) {_vm.$set(_vm.formData, "DesignationId", $$v)},expression:"formData.DesignationId"}})]}}],null,true)})],1):_vm._e(),_c('v-col',{attrs:{"cols":"12","sm":"4"}},[_c('validation-provider',{attrs:{"name":"Organization","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('v-text-field',{attrs:{"type":"text","error-messages":errors,"label":"Organization *","disabled":_vm.loading,"required":""},model:{value:(_vm.formData.OrganizationName),callback:function ($$v) {_vm.$set(_vm.formData, "OrganizationName", $$v)},expression:"formData.OrganizationName"}})]}}],null,true)})],1),_c('v-col',{directives:[{name:"show",rawName:"v-show",value:(_vm.$route.params.type === 'exporter'),expression:"$route.params.type === 'exporter'"}],attrs:{"cols":"12","sm":"4"}},[_c('v-text-field',{attrs:{"type":"text","label":"BIN No","disabled":_vm.loading},model:{value:(_vm.formData.BINNo),callback:function ($$v) {_vm.$set(_vm.formData, "BINNo", $$v)},expression:"formData.BINNo"}})],1),_c('v-col',{directives:[{name:"show",rawName:"v-show",value:(_vm.$route.params.type === 'exporter'),expression:"$route.params.type === 'exporter'"}],attrs:{"cols":"12","sm":"4"}},[_c('ValidationProvider',{attrs:{"name":"TINNo","rules":"length:12|numeric"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('v-text-field',{directives:[{name:"show",rawName:"v-show",value:(_vm.$route.params.type === 'exporter'),expression:"$route.params.type === 'exporter'"}],attrs:{"error-messages":errors,"type":"text","label":"TIN No","disabled":_vm.loading},model:{value:(_vm.formData.TINNo),callback:function ($$v) {_vm.$set(_vm.formData, "TINNo", $$v)},expression:"formData.TINNo"}})]}}],null,true)})],1),_c('v-col',{attrs:{"cols":"12","sm":"4"}},[_c('v-text-field',{attrs:{"type":"text","label":"Mobile","disabled":_vm.loading},model:{value:(_vm.formData.Mobile),callback:function ($$v) {_vm.$set(_vm.formData, "Mobile", $$v)},expression:"formData.Mobile"}})],1),_c('v-col',{attrs:{"cols":"12","sm":"4"}},[_c('v-text-field',{attrs:{"type":"text","label":"Phone","disabled":_vm.loading},model:{value:(_vm.formData.Phone),callback:function ($$v) {_vm.$set(_vm.formData, "Phone", $$v)},expression:"formData.Phone"}})],1),_c('v-col',{attrs:{"cols":"12","sm":"4"}},[_c('validation-provider',{attrs:{"name":"Email","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('v-text-field',{attrs:{"type":"email","label":"Email *","disabled":!_vm.formActions.emailEditEnabled,"error-messages":errors,"required":""},model:{value:(_vm.formData.Email),callback:function ($$v) {_vm.$set(_vm.formData, "Email", $$v)},expression:"formData.Email"}})]}}],null,true)})],1),(_vm.$route.params.id === '0' || (_vm.$route.params.id !== '0' && _vm.formData.Username !== null))?_c('v-col',{attrs:{"cols":"12","sm":"4"}},[_c('validation-provider',{attrs:{"name":_vm.getUsernameFieldName,"rules":_vm.getUsernameFieldRules},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('v-text-field',{attrs:{"type":"text","label":_vm.getUsernameFieldLabel,"disabled":!_vm.formActions.usernameEditEnabled,"error-messages":errors,"required":""},model:{value:(_vm.formData.Username),callback:function ($$v) {_vm.$set(_vm.formData, "Username", $$v)},expression:"formData.Username"}})]}}],null,true)})],1):_vm._e(),_c('v-col',{attrs:{"cols":"12","sm":"4"}},[(_vm.formActions.passwordEditEnabled)?_c('validation-provider',{attrs:{"name":"Password","rules":{required: _vm.$route.params.id === '0', min: 6},"vid":"Password"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('v-text-field',{attrs:{"type":"password","error-messages":errors,"label":_vm.$route.params.id === '0'? 'Account Password *': 'Account Password',"disabled":_vm.loading},model:{value:(_vm.formData.Password),callback:function ($$v) {_vm.$set(_vm.formData, "Password", $$v)},expression:"formData.Password"}})]}}],null,true)}):_vm._e()],1),(_vm.formActions.isAdmin && !_vm.formActions.isOwnUser)?_c('v-col',{attrs:{"cols":"12","sm":"4"}},[_c('ValidationProvider',{attrs:{"name":"Is Active","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('v-select',{attrs:{"error-messages":errors,"items":_vm.defaultSelectOptions,"item-value":"id","item-text":"label","label":"Is Login Active? *","disabled":!_vm.formActions.serviceOfficeEditEnabled,"required":""},model:{value:(_vm.formData.IsLoginActive),callback:function ($$v) {_vm.$set(_vm.formData, "IsLoginActive", $$v)},expression:"formData.IsLoginActive"}})]}}],null,true)})],1):_vm._e(),_c('v-col',{attrs:{"cols":"12","sm":"4"}},[_c('Upload',{attrs:{"label":"Upload Profile Image","show-upload":_vm.$route.params.id !== '0',"upload-disabled":!_vm.$globalHelpers.isEmptyOrUndefined(_vm.formData.ProfilePicPath),"formats":".jpg,.jpeg,.png","upload-end-point":_vm.$globalSettings.api.endpoints.user.saveProfilePic,"form-data":{userId: _vm.formData.Id},"files":_vm.uploadedFiles,"show-delete":true,"delete-end-point":_vm.$globalSettings.api.endpoints.user.deleteProfilePic},on:{"upload-loading":_vm.uploadLoading,"upload-status":_vm.uploadStatus}})],1)],1),_c('v-card-actions',{staticClass:"d-flex flex-row-reverse"},[_c('v-btn',{staticClass:"success",attrs:{"type":"submit","disabled":invalid || _vm.loading}},[_vm._v("Save")])],1)],1)]}}])})],1)],2)],1)}
var staticRenderFns = []

export { render, staticRenderFns }