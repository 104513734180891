<template>

    <div>
        <v-card :loading="loading" class="mx-auto" outlined>
            <template slot="progress">
                <v-progress-linear
                        :color="$globalSettings.progressbarClasses"
                        height="10"
                        indeterminate
                ></v-progress-linear>
            </template>

            <v-card-title>
                <v-row>
                    <v-col cols="12" sm="6">
                        <div class="secondary-text">
                            <v-btn type="button" class="default" :disabled="loading" small icon @click="navigationBack"><v-icon small>fa-angle-left</v-icon></v-btn> {{ formActions.pageTitle }}
                        </div>
                    </v-col>
                    <v-col cols="12" sm="6" class="text-right font-size-14 font-italic">
                        {{ $globalHelpers.getUserRoleName(formData.RoleId)  }}
                    </v-col>
                </v-row>
            </v-card-title>
            <v-divider></v-divider>
            <v-card-text>
                <validation-observer ref="observer" v-slot="{ invalid }">
                    <v-form @submit.prevent="save">
                        Office Information
                        <v-row>

                            <v-col cols="12" sm="4">
                                <validation-provider v-slot="{ errors }" name="Name" rules="required">
                                    <v-text-field v-model="formData.Name" type="text" :error-messages="errors" label="Name *" :disabled="loading" required></v-text-field>
                                </validation-provider>
                            </v-col>

                            <v-col cols="12" sm="4">
                                <ValidationProvider v-slot="{ errors }" name="RoleId" rules="required">
                                    <v-select v-model="formData.RoleId" :error-messages="errors" :items="roles" item-value="id" item-text="label" label="Role *" :disabled="!formActions.roleEditEnabled" v-on:change="prepareExporterTypes" required></v-select>
                                </ValidationProvider>
                            </v-col>

                            <v-col cols="12" sm="4" v-if="formActions.showServiceOffice">
                                <ValidationProvider v-slot="{ errors }" name="Service Office" rules="required">
                                    <v-select v-model="formData.ServiceOfficeId" :error-messages="errors" :items="serviceOffices" item-value="id" item-text="label" label="Service Office *" :disabled="!formActions.serviceOfficeEditEnabled" v-on:change="prepareExporterTypes" required></v-select>
                                </ValidationProvider>
                            </v-col>

                            <v-col cols="12" sm="4" v-if="formActions.showDepartment">
                                <ValidationProvider v-slot="{ errors }" :name="formData.RoleId === $globalEnums.roles.Exporter? 'Exporter Type': 'Department'" rules="required">
                                    <v-select v-model="formData.ExporterType" :error-messages="errors" :items="exporterTypes" item-value="id" item-text="label" :label="formData.RoleId === $globalEnums.roles.Exporter? 'Exporter Type *': 'Department *'" :disabled="!formActions.departmentEditEnabled" required></v-select>
                                </ValidationProvider>
                            </v-col>

                            <v-col cols="12" sm="4" v-if="formActions.showIsDepartmentHead">
                                <ValidationProvider v-slot="{ errors }" name="Is Department Head" rules="required">
                                    <v-select v-model="formData.IsDepartmentHead" :error-messages="errors" :items="defaultSelectOptions" item-value="id" item-text="label" label="Is Department Head? *" :disabled="!formActions.serviceOfficeEditEnabled" required></v-select>
                                </ValidationProvider>
                            </v-col>

                            <v-col cols="12" sm="4" v-if="formActions.isAdmin">
                                <ValidationProvider v-slot="{ errors }" name="DesignationId" :rules="{required: $route.params.type !== 'exporter'}">
                                    <v-select v-model="formData.DesignationId" :error-messages="errors" :items="designations" item-value="id" item-text="label" :label="'Designation' + ($route.params.type !== 'exporter'? '*': '')" :disabled="!formActions.designationEditEnabled" required></v-select>
                                </ValidationProvider>
                            </v-col>

                            <v-col cols="12" sm="4">
                                <validation-provider v-slot="{ errors }" name="Organization" rules="required">
                                    <v-text-field v-model="formData.OrganizationName" type="text" :error-messages="errors" label="Organization *" :disabled="loading" required></v-text-field>
                                </validation-provider>
                            </v-col>

                            <v-col cols="12" sm="4" v-show="$route.params.type === 'exporter'">
                                <v-text-field v-model="formData.BINNo" type="text" label="BIN No" :disabled="loading"></v-text-field>
                            </v-col>

                            <v-col cols="12" sm="4" v-show="$route.params.type === 'exporter'">
                                <ValidationProvider v-slot="{ errors }" name="TINNo" rules="length:12|numeric">
                                    <v-text-field v-model="formData.TINNo" :error-messages="errors" type="text" label="TIN No" :disabled="loading" v-show="$route.params.type === 'exporter'"></v-text-field>
                                </ValidationProvider>
                            </v-col>

                            <v-col cols="12" sm="4">
                                <v-text-field v-model="formData.Mobile" type="text" label="Mobile" :disabled="loading"></v-text-field>
                            </v-col>

                            <v-col cols="12" sm="4">
                                <v-text-field v-model="formData.Phone" type="text" label="Phone" :disabled="loading"></v-text-field>
                            </v-col>

                            <v-col cols="12" sm="4">
                                <validation-provider v-slot="{ errors }" name="Email" rules="required">
                                    <v-text-field v-model="formData.Email" type="email" label="Email *" :disabled="!formActions.emailEditEnabled" :error-messages="errors" required></v-text-field>
                                </validation-provider>
                            </v-col>

                            <v-col cols="12" sm="4" v-if="$route.params.id === '0' || ($route.params.id !== '0' && formData.Username !== null)">
                                <validation-provider v-slot="{ errors }" :name="getUsernameFieldName" :rules="getUsernameFieldRules">
                                    <v-text-field v-model="formData.Username" type="text" :label="getUsernameFieldLabel" :disabled="!formActions.usernameEditEnabled" :error-messages="errors" required></v-text-field>
                                </validation-provider>
                            </v-col>

                            <v-col cols="12" sm="4">
                                <validation-provider v-slot="{ errors }" name="Password" :rules="{required: $route.params.id === '0', min: 6}" vid="Password" v-if="formActions.passwordEditEnabled">
                                    <v-text-field v-model="formData.Password" type="password" :error-messages="errors" :label="$route.params.id === '0'? 'Account Password *': 'Account Password'" :disabled="loading"></v-text-field>
                                </validation-provider>
                            </v-col>

                            <v-col cols="12" sm="4" v-if="formActions.isAdmin && !formActions.isOwnUser">
                                <ValidationProvider v-slot="{ errors }" name="Is Active" rules="required">
                                    <v-select v-model="formData.IsLoginActive" :error-messages="errors" :items="defaultSelectOptions" item-value="id" item-text="label" label="Is Login Active? *" :disabled="!formActions.serviceOfficeEditEnabled" required></v-select>
                                </ValidationProvider>
                            </v-col>

                            <v-col cols="12" sm="4">
                                <Upload label="Upload Profile Image" :show-upload="$route.params.id !== '0'" :upload-disabled="!$globalHelpers.isEmptyOrUndefined(formData.ProfilePicPath)" formats=".jpg,.jpeg,.png" :upload-end-point="$globalSettings.api.endpoints.user.saveProfilePic" :form-data="{userId: formData.Id}" :files="uploadedFiles" :show-delete="true" :delete-end-point="$globalSettings.api.endpoints.user.deleteProfilePic" @upload-loading="uploadLoading" @upload-status="uploadStatus"></Upload>
                            </v-col>

                        </v-row>

                        <v-card-actions class="d-flex flex-row-reverse">
                            <v-btn type="submit" class="success" :disabled="invalid || loading">Save</v-btn>
                        </v-card-actions>
                    </v-form>
                </validation-observer>
            </v-card-text>
        </v-card>
    </div>

</template>

<script>
    import Vue from 'vue';
    import {required, email, length, numeric} from 'vee-validate/dist/rules';
    import { extend, ValidationObserver, ValidationProvider } from 'vee-validate';
    import Upload from "@/components/media/Upload";

    extend('required', {
        ...required,
        message: '{_field_} can not be empty',
    });
    extend('email', {
        ...email,
        message: 'This field must be an email'
    });
    extend('length', {
        ...length,
        message: 'This field must be exactly {length} characters'
    });
    extend('numeric', {
        ...numeric,
        message: 'This field must be a number'
    });
    extend('alphanumericExporterUsername', {
        validate(value) {
            return /^[a-zA-Z0-9]*$/.test(value);
        },
        message: '{_field_} may only contain letters and numbers'
    });
    extend('alphanumericUsername', {
        validate(value) {
            return /^[a-zA-Z0-9-_]*$/.test(value);
        },
        message: '{_field_} may only contain letters, numbers, dashes, and underscores'
    });

    export default {
        name: "User_view",
        data: () => ({
            loading: false,
            
            formActions: {
                pageTitle: '',
                showDepartment: false,
                showIsDepartmentHead: false,
                showServiceOffice: false,
                departmentEditEnabled: false,
                usernameEditEnabled: false,
                emailEditEnabled: false,
                roleEditEnabled: false,
                designationEditEnabled: false,
                serviceOfficeEditEnabled: false,
                passwordEditEnabled: false,
                isAdmin: false,
                isOwnUser: false,
            },

            profileImage:{
                endPoint: '',
                formData: {},
            },

            roles: [],
            exporterTypes: [],
            serviceOffices: [],
            designations: [],
            defaultSelectOptions: [{ id: 0, label: 'No' }, { id: 1, label: 'Yes' }],

            formData: {
                Id: null,
                IsLoginActive: null,
                IsDepartmentHead: 0,
            },
        }),
        components: {
            ValidationProvider,
            ValidationObserver,
            Upload
        },
        computed:{
            usernamePrefix: function (){
                if(this.$route.params.id === '0' && this.formData.RoleId === this.$globalEnums.roles.Exporter && this.formData.ExporterType === this.$globalEnums.ExporterTypes.Textile){
                    //console.log('bd');
                    return 'BD';
                }
                if(this.$route.params.id === '0' && this.formData.RoleId === this.$globalEnums.roles.Exporter && this.formData.ExporterType === this.$globalEnums.ExporterTypes.NonTextile){
                    return 'NT';
                }
                return '';
            },
            getUsernameFieldRules() {
                if (this.$route.params.type === 'exporter') {
                    return 'required|alphanumericExporterUsername';
                } else {
                    return 'required|alphanumericUsername';
                }
            },
            getUsernameFieldLabel() {
                return this.$route.params.type === 'exporter' ? 'Exporter ID *' : 'Username *';
            },
            getUsernameFieldName() {
                return this.$route.params.type === 'exporter' ? 'Exporter ID' : 'Username';
            },
            uploadedFiles: function (){
                if(!this.$globalHelpers.isEmptyOrUndefined(this.formData.ProfilePicPath)){
                    return [{ Id: this.formData.Id, MimeType: 'image/jpeg', FilePath: this.$apiBaseURL + this.$globalSettings.api.endpoints.media.profilePics + this.formData.ProfilePicPath }];
                }
                return [];
            }
        },
        methods: {
            loadSavedData: async function () {
                this.loading = true;
                await this.$axios.get(this.$globalSettings.api.endpoints.user.get, { params: { id: this.$route.params.id} }).then(async (response) => {
                    this.loading = false;
                    if (response.data.Status === 'success') {
                        this.formData = response.data.Data;
                        this.prepareExporterTypes();
                    }else{
                        Vue.$toast.open({message: response.data.Msg, type: response.data.Status});
                    }
                }).catch((error) => {
                    this.loading = false;
                    this.$globalHelpers.processHttpErrorsToast(error);
                });
            },
            save: async function () {
                this.loading = true;
                await this.$axios.post(this.$globalSettings.api.endpoints.user.save, this.formData).then(async (response) => {
                    this.loading = false;
                    Vue.$toast.open({message: response.data.Msg, type: response.data.Status});
                    if (response.data.Status === 'success') {
                        if(this.formActions.isOwnUser) {
                            await this.$globalHelpers.logout();
                        }else{
                            await this.$router.push({name: 'App.User.List', params: {type: this.formData.RoleId === this.$globalEnums.roles.Exporter? 'exporters': 'users'}});
                        }
                    }
                }).catch((error) => {
                    this.loading = false;
                    this.$globalHelpers.processHttpErrorsToast(error);
                });
            },
            prepareExporterTypes: function () {
                this.serviceOffices = this.$globalHelpers.enumToArray(this.$globalEnums.ServiceOffices).filter(x => x.id !== this.$globalEnums.ServiceOffices.None);

                this.formActions.showServiceOffice = false;
                this.formActions.showDepartment = false;
                this.formActions.showIsDepartmentHead = 0;

                //for non admin role
                if(this.formData.RoleId !== this.$globalEnums.roles.Admin){
                    this.formActions.showServiceOffice = true;

                    //Departments for Exporters
                    if(this.$route.params.type === 'exporter') {
                        this.formActions.showDepartment = true;
                        this.exporterTypes = this.$globalHelpers.enumToArray(this.$globalEnums.ExporterTypes).filter(x => x.id <= 2 && x.id !== this.$globalEnums.ExporterTypes.None);

                        this.formData.IsDepartmentHead = 0;
                    }
                    //Departments for other users except users of Admin role
                    else{
                        if(this.formData.ServiceOfficeId === this.$globalEnums.ServiceOffices.Dhaka){
                            this.formActions.showDepartment = true;
                            this.formData.IsDepartmentHead = 0;
                        }
                        else{
                            if(this.formData.ServiceOfficeId !== this.$globalEnums.ServiceOffices.Chattogram && this.formData.RoleId < this.$globalEnums.roles.Director) {
                                this.formActions.showIsDepartmentHead = true;
                            }else{
                                this.formActions.showIsDepartmentHead = false;
                                this.formData.IsDepartmentHead = 0;
                            }
                            this.formData.ExporterType = 0;
                        }
                        this.exporterTypes = this.$globalHelpers.enumToArray(this.$globalEnums.ExporterTypes);
                    }
                }
                //for Admin role
                else{
                    this.formData.IsDepartmentHead = 0;
                    this.formData.ExporterType = 0;
                    /*if(this.formData.ExporterType !== this.$globalEnums.ExporterTypes.None){
                        this.formData.ExporterType = null;
                    }

                    //For users of Admin role, no exporter type is needed or allowed, that's why only None is displayed in the dropdown
                    this.exporterTypes = this.$globalHelpers.enumToArray(this.$globalEnums.ExporterTypes).filter(x => x.id === this.$globalEnums.ExporterTypes.None);

                    //For users of Admin role, no Service Office is needed or allowed, that's why only None is displayed in the dropdown
                    this.serviceOffices = this.$globalHelpers.enumToArray(this.$globalEnums.ServiceOffices).filter(x => x.id === this.$globalEnums.ServiceOffices.None);*/
                }
            },
            preparePageMode: function (){
                if (this.$globalHelpers.getAuthStoreValue('user_role_id') === this.$globalEnums.roles.Admin) {
                    this.formActions.isAdmin = true;
                }
                console.log('this.formActions.isAdmin', this.formActions.isAdmin);

                if(this.$route.params.id === '0'){
                    if(this.$route.params.type === 'exporter'){
                        this.formActions.pageTitle = 'Add Exporter';
                        this.roles = this.roles.filter(x => x.id === this.$globalEnums.roles.Exporter);
                        this.formData.RoleId = this.roles[0].id;
                        this.exporterTypes = this.$globalHelpers.enumToArray(this.$globalEnums.ExporterTypes).filter(x => x.id <= 2 && x.id !== this.$globalEnums.ExporterTypes.None);
                    }else{
                        this.formActions.pageTitle = 'Add EPB User';
                        this.roles = this.roles.filter(x => x.id !== this.$globalEnums.roles.Exporter);
                        this.exporterTypes = this.$globalHelpers.enumToArray(this.$globalEnums.ExporterTypes);
                    }
                    this.formActions.departmentEditEnabled = true;
                    this.formActions.usernameEditEnabled = true;
                    this.formActions.emailEditEnabled = true;
                    this.formActions.roleEditEnabled = true;
                    this.formActions.designationEditEnabled = true;
                    this.formActions.serviceOfficeEditEnabled = true;
                    this.formActions.passwordEditEnabled = true;
                }else{
                    //this.formActions.pageTitle = this.$globalHelpers.getUserRoleName(this.formData.RoleId) + ': ' + (this.formData.Username? this.formData.Username: this.formData.Email);
                    this.formActions.pageTitle = this.formData.Username? this.formData.Username: this.formData.Email;

                    if (this.$globalHelpers.getAuthStoreValue('user_id') === this.$route.params.id) {
                        this.formActions.isOwnUser = true;
                    }

                    this.formActions.departmentEditEnabled = this.formActions.isAdmin;
                    this.formActions.usernameEditEnabled = this.formActions.isAdmin;
                    this.formActions.emailEditEnabled = this.formActions.isAdmin;
                    this.formActions.roleEditEnabled = this.formActions.isAdmin;
                    this.formActions.designationEditEnabled = this.formActions.isAdmin;
                    this.formActions.serviceOfficeEditEnabled = this.formActions.isAdmin;
                    //this.formActions.passwordEditEnabled = !this.formData.Username;
                    this.formActions.passwordEditEnabled = true;
                }

                this.designations = [];
                if(this.$route.params.type === 'exporter'){
                    this.designations = this.$globalHelpers.enumToArray(this.$globalEnums.Designations).filter(x => x.id === null || (x.id >= 100));
                }else{
                    this.designations = this.$globalHelpers.enumToArray(this.$globalEnums.Designations).filter(x => x.id <= 50);
                }
            },
            uploadLoading: function (loading){
                this.loading = loading;
            },
            uploadStatus: function (status){
                Vue.$toast.open({message: status.message, type: status.status});
                if(status.status === 'success'){
                    this.loadSavedData();
                }
            },
            navigationBack: async function () {
                this.$router.go(-1);
            },
        },
        async created() {
            this.roles = this.$globalHelpers.enumToArray(this.$globalEnums.roles);
            //console.log(this.roles);
            if(this.$route.params.id !== '0') {
                await this.loadSavedData();
            }else{
                this.prepareExporterTypes();
            }
            this.preparePageMode();
        }
    }
</script>

<style scoped>
    .app-name{
        color: #794986;
        font-size: 20px;
    }
    .background{
        background: rgb(101,74,134);
        background: linear-gradient(228deg, rgba(101,74,134,1) 0%, rgba(84,66,145,1) 100%);
    }
    .login-left-bg{

    }
    .login-right-bg{
        background: #512DA8;
    }
    .file-input{
        max-width: 375px;
    }
</style>