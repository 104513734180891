<template>
	<div>
		<v-file-input v-if="showUpload" show-size :clearable="true" truncate-length="100" :label="label" :accept="formats" @change="upload" v-model="formActions.fileName" :disabled="uploadDisabled || loading" ref="input"></v-file-input>

        <div class="images mt-5" v-viewer="{movable: false}" v-for="file in files" v-bind:key="file.id">

            <div class="attachmentImageContainer" v-if="file.MimeType === 'application/pdf'">
                <v-row class="mt-10">
                    <v-col class="attachment attachmentPdfContainer">
                        <iframe :src="file.FilePath" width="100%" height="500"></iframe>
                        <v-btn v-if="showDelete" type="button" fab x-small class="error pl-1 pr-0" @click="deleteFile(file.Id)"><v-icon small>fas fa-minus</v-icon></v-btn>
                    </v-col>
                </v-row>
            </div>
            <div class="attachmentImageContainer" v-else>
                <img v-if="file.MimeType !== 'application/pdf'" :aspect-ratio="16/9" :src="file.FilePath" class="attachmentImage">
                <div>
                    <v-btn v-if="showDelete" type="button" fab x-small class="error pl-1 pr-0" @click="deleteFile(file.Id)"><v-icon small>fas fa-minus</v-icon></v-btn>
                </div>
            </div>

        </div>
        <div class="clearfix"></div>

	</div>
</template>

<script>
import 'viewerjs/dist/viewer.css';
import { directive as viewer } from "v-viewer"

export default {
	name: "Upload",
	data: () => ({
        loading: false,
        formActions: {
            fileName: null,
        },
    }),
    props: {
        label: {
            type: String,
            default: () => 'Upload',
        },
        showUpload: {
            type: Boolean,
            default: () => false,
        },
        uploadDisabled: {
            type: Boolean,
            default: () => false,
        },
        uploadDisabledMsg: {
            type: String,
            default: () => 'Upload is disabled!',
        },
        formats: {
            type: String,
            default: () => '.jpg,.jpeg,.png,.pdf',
        },
        uploadEndPoint: {
            type: String,
            default: () => null,
        },
        formData: {
            type: Object,
            default: () => {},
        },
        files: {
            type: Array,
            default: () => [],
        },
        showDelete: {
            type: Boolean,
            default: () => true,
        },
        deleteEndPoint: {
            type: String,
            default: () => null,
        },
    },
    directives: {
        viewer: viewer({
            debug: false,
        }),
    },
    methods: {
        upload: async function (file) {
            if (file) {
                if (!this.uploadDisabled) {
                    this.loading = true;
                    this.$emit('upload-loading', this.loading);

                    let fileUpload = await this.$globalHelpers.saveFile(file, this.uploadEndPoint, this.formData);
                    this.loading = false;
                    this.$emit('upload-loading', this.loading);

                    this.$emit('upload-status', {message: fileUpload.Msg, status: fileUpload.Status});
                    this.formActions.fileName = null;
                } else {
                    this.formActions.fileName = null;
                    this.$emit('upload-status', {message: this.uploadDisabledMsg, status: 'error'});
                }
            }/*else{
                this.$emit('upload-status', {message: 'Please select a file!', status: 'error'});
            }*/
        },
        deleteFile: async function(id){
            if (this.showDelete) {
                if (confirm("Are your sure that you want to delete this file?") === true) {
                    this.loading = true;
                    this.$emit('upload-loading', this.loading);

                    await this.$axios.get(this.deleteEndPoint, {params: {id: id}}).then(async (response) => {
                        this.loading = false;
                        this.$emit('upload-loading', this.loading);

                        this.$emit('upload-status', {message: response.data.Msg, status: response.data.Status});
                    }).catch((error) => {
                        this.loading = false;
                        this.$emit('upload-loading', this.loading);
                        this.$globalHelpers.processHttpErrorsToast(error);
                    });
                }
            } else {
                this.formActions.fileName = null;
                this.$emit('upload-status', {message: 'You cannot perform this action', status: 'error'});
            }
        },
    },
}
</script>

<style scoped>

</style>